import React from 'react'
import LottieComponent, { LottieProps } from 'react-lottie'

import { useInView } from 'react-hook-inview/dist'

const Lottie = (props) => {
  const [ref, inView] = useInView()

  const defaultOptions: LottieProps['options'] = React.useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: props.data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [props.data]
  )

  return (
    <div ref={ref} className="w-full max-w-md mx-auto">
      <LottieComponent options={defaultOptions} isStopped={!inView} isPaused={!inView} />
    </div>
  )
}

export default React.memo(Lottie)
